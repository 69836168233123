export const WrappedCollectionsSvg = (props) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='icon mr-2 mt-1 h-5 w-5 bg-white dark:bg-jacarta-900'
      {...props}
    >
      <rect x='9.5' y='9.5' width='7' height='9' stroke='currentColor' />
      <rect y='2' width='8' height='10' fill='currentColor' />
      <rect y='13' width='8' height='6' fill='currentColor' />
      <rect x='9' y='2' width='8' height='6' fill='currentColor' />
      <line x1='12' y1='4.5' x2='20' y2='4.5' stroke='currentColor' />
      <line x1='19.5' y1='5' x2='19.5' y2='15' stroke='currentColor' />
      <line x1='20' y1='14.5' x2='13' y2='14.5' stroke='currentColor' />
      <path d='M12 14.5L14 13L14 16L12 14.5Z' fill='currentColor' />
    </svg>
  );
};
